import React, { Component } from "react";

export type PropData = {
    paragraph: string;
    title: string;
    items: Item[];
}

type Item = {
    title: string;
    imgSmall: string;
    imgLarge: string;
}

type Props = {
    data: PropData;
};

export class Products extends Component<Props> {
    render() {
        return (
            <div id="products" className="text-center">
                <div className="container">
                    <div className="section-title">
                        <h2>{this.props.data.title}</h2>
                        <p>{this.props.data.paragraph}</p>
                    </div>
                    <div className="row">
                        <div className="products-items">

                            {this.props.data.items.map((d, i) => (

                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <span>{d.title}</span>
                                    <div className="products-item">
                                        <div className="hover-bg">
                                            {" "}
                                            <a
                                                href={d.imgLarge}
                                                title={d.title}
                                                data-lightbox-gallery="gallery1"
                                            >
                                                <div className="hover-text">
                                                    <h4>{d.title}</h4>
                                                </div>
                                                <img
                                                    src={d.imgSmall}
                                                    className="img-responsive"
                                                    alt={d.title}
                                                />{" "}
                                            </a>{" "}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Products;