import React, { Component } from "react";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export type PropData = {
    title: string;
    paragraph: string;
    items: Item[];
}

type Item = {
    icon: [IconPrefix, IconName];
    name: string;
    text: string;
}

type Props = {
    data: PropData;
}

export class Services extends Component<Props> {
    render() {
        return (
            <div id="services" className="text-center">
                <div className="container">
                    <div className="section-title">
                        <h2>{this.props.data.title}</h2>
                        <p>{this.props.data.paragraph}</p>
                    </div>
                    <div className="row">
                        {this.props.data.items.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4" style={{ "height": "200px" }}>
                                {" "}
                                <FontAwesomeIcon icon={d.icon} size="3x" />
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;