import React, { Component } from 'react'

export type PropData = {
    title: string;
    paragraph: string;
    whyTitle: string;
    Why: string[];
    Why2: string[];
}

type Props = {
    data: PropData;
}

export class About extends Component<Props> {
    render() {
        return (
            <div id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt="" /> </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="about-text">
                                <h2>{this.props.data.title}</h2>
                                <p>{this.props.data.paragraph}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About