import React, { Component, FormEvent } from "react";
import emailjs from 'emailjs-com';
import { useForm } from "react-hook-form";

export type PropData = {
    address: string;
    addressLabel: string;

    email: string;
    emailLabel: string;

    phone?: string;
    phoneLabel?: string;

    facebook: string;
    twitter: string;
    youtube: string;
    site: string;
    siteLabel: string;
    contactInfo: ContactInfo;
}

type Props = {
    data: PropData;
}

type ContactInfo = {
    serviceId: string;
    templateId: string;
    userId: string;
    successMessage: string;
    errorMessage: string;
    spinnerLabel: string;
    title: string;
    paragraph: string;
    formTitle: string;
    nameLabel: string;
    nameRequiredMessage: string;
    nameMinLengthMessage: string;
    emailLabel: string;
    emailRequiredMessage: string;
    emailRegexMessage: string;
    messageLabel: string;
    messageRequiredMessage: string;
    messageMinLengthMessage: string;
    sendMessagelabel: string;
}

function ContactForm(props: { contactInfo: ContactInfo, disabled?: boolean, sendEmail: ((event: React.FormEvent<HTMLFormElement>) => void) }) {

    const { register, handleSubmit, errors } = useForm<FormData>();
    const errorsDyn: any = errors;

    return (
        <form name="sentMessage" id="contactForm" onSubmit={handleSubmit((data: any, event: any) => { props.sendEmail(event); })}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder={props.contactInfo.nameLabel}
                            name="userName"
                            required={true}
                            disabled={props.disabled}
                            ref={register({
                                required: props.contactInfo.nameRequiredMessage,
                                minLength: {
                                    value: 2,
                                    message: props.contactInfo.nameMinLengthMessage,
                                },
                            })}
                        />
                        <p className="help-block text-danger">{errorsDyn.userName && errorsDyn.userName.message}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder={props.contactInfo.emailLabel}
                            name="userEmail"
                            required={true}
                            disabled={props.disabled}
                            ref={register({
                                required: props.contactInfo.emailRequiredMessage,
                                pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: props.contactInfo.emailRegexMessage,
                                },
                            })}
                        />
                        <p className="help-block text-danger">{errorsDyn.userEmail && errorsDyn.userEmail.message}</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows={4}
                    placeholder={props.contactInfo.messageLabel}
                    required
                    disabled={props.disabled}
                    ref={register({
                        required: props.contactInfo.messageRequiredMessage,
                        minLength: {
                            value: 2,
                            message: props.contactInfo.messageMinLengthMessage,
                        },
                    })}
                ></textarea>
                <p className="help-block text-danger">{errorsDyn.message && errorsDyn.message.message}</p>
            </div>
            <div id="success"></div>
            <button type="submit" className="btn btn-custom btn-lg" value="Send">
                {props.contactInfo.sendMessagelabel}
            </button>
        </form >
    );
}

type State = {
    fetching?: boolean;
    success?: string;
    error?: string;
}

export class Contact extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    sendEmail(e: FormEvent<HTMLFormElement>) {
        emailjs.sendForm(this.props.data.contactInfo.serviceId, this.props.data.contactInfo.templateId, e.target as HTMLFormElement,
            this.props.data.contactInfo.userId)
            .then((result) => {
                this.setState({ ...this.state, fetching: false, success: this.props.data.contactInfo.successMessage });
            }, (error) => {
                this.setState({ ...this.state, fetching: false, error: this.props.data.contactInfo.errorMessage });
            });
    };

    spinner() {
        return (<div className="spinner-border" role="status">
            <span className="sr-only">{this.props.data.contactInfo.spinnerLabel}</span>
        </div>);
    }

    render() {
        return (
            <div>
                <div id="contact">
                    <div className="container">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="section-title">
                                    <h2>{this.props.data.contactInfo.title}</h2>
                                    <p> {this.props.data.contactInfo.paragraph}
                                    </p>
                                </div>
                                {this.state.fetching && <div className="alert alert-primary" role="alert">
                                    {this.state.fetching && this.spinner}
                                </div>}

                                {this.state.success && <div className="alert alert-success" role="alert">
                                    {this.state.success && this.state.success}
                                </div>}

                                {this.state.error && <div className="alert alert-warning" role="alert">
                                    {this.state.error && this.state.error}
                                </div>}

                                <ContactForm key="contactForm" contactInfo={this.props.data.contactInfo} disabled={this.state.fetching} sendEmail={(e: FormEvent<HTMLFormElement>) => {
                                    this.sendEmail(e);
                                }}></ContactForm>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-1 contact-info">
                            <div className="contact-item">
                                <h3>{this.props.data.contactInfo.formTitle}</h3>
                                <p>
                                    <span>
                                        <i className="fa fa-map-marker"></i>{this.props.data.addressLabel}
                                    </span>
                                    {this.props.data.address}
                                </p>
                            </div>
                            {this.props.data.phone !== undefined &&
                                <div className="contact-item">
                                    <p>
                                        <span>
                                            <i className="fa fa-phone"></i>{this.props.data.phoneLabel}
                                        </span>{" "}
                                        {this.props.data.phone}
                                    </p>
                                </div>
                            }

                            <div className="contact-item">
                                <p>
                                    <span>
                                        <i className="fa fa-envelope-o"></i>{this.props.data.emailLabel}
                                    </span>{" "}
                                    {this.props.data.email}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                href={this.props.data ? this.props.data.facebook : "/"}
                                            >
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={this.props.data ? this.props.data.twitter : "/"}>
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={this.props.data ? this.props.data.youtube : "/"}>
                                                <i className="fa fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id="footer">
                    <div className="container text-center">
                        <p>
                            &copy; 2020 {" "}
                            <a href={this.props.data.site} rel="nofollow">
                                {this.props.data.siteLabel}
                            </a>
                        </p>
                    </div>
                    <div className="container text-center">
                        <p>

                            <a href="https://www.livroreclamacoes.pt/inicio" rel="nofollow">
                                <img src="./img/i010901.png" alt="Livro de Reclamações" />
                            </a>
                        </p>
                    </div>
                </div>
            </div >
        );
    }
}

export default Contact;