import React, { Component } from "react";

export type PropData = {
    paragraph: string;
    title: string;
    learnMore: string;
}

type Props = {
    data: PropData;
}

export class Header extends Component<Props> {
    render() {
        return (
            <header id="header">
                <div className="intro">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2 intro-text">
                                    <h1>
                                        {this.props.data.title}
                                        <span></span>
                                    </h1>
                                    <p>
                                        {this.props.data.paragraph}
                                    </p>
                                    <a
                                        href="#areas"
                                        className="btn btn-custom btn-lg page-scroll"
                                    >
                                        {this.props.data.learnMore}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;