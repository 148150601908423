import React, { Component } from "react";


export type PropData = {
    title: string;
    toggleNavigationLabel: string;
    areasLabel: string;
    aboutLabel: string;
    servicesLabel: string;
    productsLabel: string;
    contactLabel: string;
};


type Props = {
    data: PropData;
};

export class Navigation extends Component<Props> {
    render() {
        return (
            <nav id="menu" className="navbar navbar-default navbar-fixed-top">
                <div className="container">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                        >
                            {" "}
                            <span className="sr-only">{this.props.data.toggleNavigationLabel}</span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                        </button>

                        <a href="#page-top">
                            <img height="50px" src="./img/logo.png" alt="GoMicro"></img>
                        </a>

                        <a className="navbar-brand page-scroll" href="#page-top">

                            {this.props.data.title}
                        </a>{" "}

                    </div>

                    <div
                        className="collapse navbar-collapse"
                        id="bs-example-navbar-collapse-1"
                    >
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="#areas" className="page-scroll">
                                    {this.props.data.areasLabel}
                                </a>
                            </li>
                            <li>
                                <a href="#about" className="page-scroll">
                                    {this.props.data.aboutLabel}
                                </a>
                            </li>
                            <li>
                                <a href="#services" className="page-scroll">
                                    {this.props.data.servicesLabel}
                                </a>
                            </li>
                            <li>
                                <a href="#products" className="page-scroll">
                                    {this.props.data.productsLabel}
                                </a>
                            </li>
                            <li>
                                <a href="#contact" className="page-scroll">
                                    {this.props.data.contactLabel}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navigation;