import React, { Component } from 'react'
import Navigation from './components/Navigation';
import { PropData as NavigationPropData } from './components/Navigation';
import Header from './components/Header';
import { PropData as HeaderPropData } from './components/Header';
import Areas from './components/Areas';
import { PropData as AreasPropData } from './components/Areas';
import About from './components/About';
import { PropData as AboutPropData } from './components/About';
import Services from './components/Services';
import { PropData as ServicesPropData } from './components/Services';
import { PropData as TestimonialsPropData } from './components/testimonials';
import { PropData as TeamPropData } from './components/Team';
import Contact from './components/Contact';
import { PropData as ContactPropData } from './components/Contact';
import JsonData from './data/data.json';
import Products from './components/Products';
import { PropData as ProductsPropData } from './components/Products';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDraftingCompass, faWrench, faRunning, faPlane, faCodeBranch, faTools, faGift, faChalkboardTeacher, faHeadset, faFlask } from '@fortawesome/free-solid-svg-icons'

library.add(faDraftingCompass, faWrench, faRunning, faPlane, faCodeBranch, faTools, faGift, faChalkboardTeacher, faHeadset, faFlask);

type Props = {}

type State = {
  landingPageData: LandingPageData
}

type LandingPageData = {
  Header: HeaderPropData;
  Areas: AreasPropData;
  About: AboutPropData;
  Services: ServicesPropData;
  Products: ProductsPropData;
  Team: TeamPropData[];
  Contact: ContactPropData;
  Testimonials: TestimonialsPropData[];
  Navigation: NavigationPropData;
}

export class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      landingPageData: JsonData as LandingPageData,
    }
  }

  getlandingPageData() {
    this.setState({ landingPageData: JsonData as LandingPageData })
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation data={this.state.landingPageData.Navigation} />
        <Header data={this.state.landingPageData.Header} />
        <Areas data={this.state.landingPageData.Areas} />
        <About data={this.state.landingPageData.About} />
        <Services data={this.state.landingPageData.Services} />
        <Products data={this.state.landingPageData.Products} />
        { //<Testimonials data={this.state.landingPageData.Testimonials} /> 
          //<Team data={this.state.landingPageData.Team} />
        }
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    )
  }
}

export default App;