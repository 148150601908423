import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/free-solid-svg-icons'




export type PropData = {
    title: string;
    items: Items[];
}

type Items = {
    icon: [IconPrefix, IconName];
    title: string;
    text: string;
}

type Props = {
    data: PropData;
}

export class Areas extends Component<Props> {
    render() {
        return (
            <div id="areas" className="text-center">
                <div className="container">
                    <div className="col-md-10 col-md-offset-1 section-title">
                        <h2>{this.props.data.title}</h2>
                    </div>
                    <div className="row">
                        {this.props.data.items.map((d, i) => (
                            <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3" style={{ height: "200px" }}>
                                {" "}
                                <FontAwesomeIcon icon={d.icon} size="4x" />
                                <h3>{d.title}</h3>
                                <p>{d.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Areas;